@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #222;
  color: #e5ccab;
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
h1 {
	font-size: 22px;
}
.day-nav-bar {
  display: flex;
  width: 100%;
  font-size: 40px;
}
.nav-bar-arrow {
  width: 120px;
  padding: 10px 20px;
  cursor: pointer;
}
.nav-bar-date-container {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.page-container {
	width: 500px;
	max-width: 100%;
	margin: 0 auto;
	height: 100vh;
	position: relative;
	overflow: hidden;
}
.AppContainer {
	width: 500px;
	max-width: 100%;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 50%;
	bottom: 80px;
	overflow-x: hidden;
	transform: translateX(-50%);
	scrollbar-color: #272d33;
  scrollbar-width: thin;
}
.AppContainer::-webkit-scrollbar {
  width: 1em;
	background-color:#22222288;
}

.AppContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.352);
}

.AppContainer::-webkit-scrollbar-thumb {
	border-radius: 8px;
  background-color: #37424e;
}
.night-mode {
  background: linear-gradient(180deg, #272d33 40%, rgb(53, 37, 29) 60%);
	background-color: #272d33;
  color: #e5ccab;
}
.day-mode {
  background-color: #add8dd;
  color: #222;
}

.button {
  margin: 5px auto;
  width: 400px;
  max-width: 80%;
  background-color: #ef9641;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 44px 2px rgba(248, 211, 150, 1) inset,
    0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
  -moz-box-shadow: 0px 0px 44px 2px rgba(248, 211, 150, 1) inset,
    0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
  box-shadow: 0px 0px 24px 2px rgb(255, 214, 148) inset,
    0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
  cursor: pointer;
	z-index: 100;
	position: relative;
}
.blue-button {
  margin: 5px auto;
  width: 400px;
  max-width: 80%;
  background-color: #293139;
  color: rgb(255, 255, 255);
  display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 44px 2px #6b857e inset,
    0px 0px 44px 2px #6b857e inset;
  -moz-box-shadow: 0px 0px 44px 2px #6b857e inset,
    0px 0px 44px 2px #6b857e inset;
  box-shadow: 0px 0px 24px 2px #6b857e inset, 0px 0px 44px 2px #6b857e inset;
  cursor: pointer;
  border: 0;
}
.day-sleep-button {
  margin: 5px auto;
  width: 400px;
  max-width: 80%;
  background-color: #ef9641;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 18px;
  cursor: pointer;
	-webkit-box-shadow: 0px 0px 44px 2px rgba(248, 211, 150, 1) inset,
	0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
-moz-box-shadow: 0px 0px 44px 2px rgba(248, 211, 150, 1) inset,
	0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
box-shadow: 0px 0px 24px 2px rgb(255, 214, 148) inset,
	0px 0px 44px 2px #bb8852, 0px 0px 64px 2px #794c1b;
	z-index: 100;
}
.day-sleep-button .text {
  text-transform: none!important;
  font-size: 16px;
margin-bottom: 10px;
text-align: center;
}
.day-sleep-button label, .blue-button label {
  font-size: 16px;
  text-transform: none;
}
.day-sleep-button textarea, .blue-button textarea {
  width: 100%;
  border: 0;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  height: 80px;
}
.day-dream-container {
  display: flex;
  width: 400px;
	max-width: 80%;
  margin: 0 auto;
  justify-items: center;
  align-items: center;
	padding: 10px 25px;
	background-color: rgba(255,255,255, 0.1);
	border-radius: 8px;
margin-bottom: 10px;
z-index: 100;
height: 100%;
}

.day-dream-container .info {
  width: 100%;
}
.day-dream-container .edit {
cursor: pointer;
margin-left: 10px;
font-size: 20px;
}

.nav-bar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	position: fixed;
	bottom: 0;
	width: 500px;
	max-width: 100%;
	height: 80px;
	background-color: #272d33;
z-index: 1000;
}
.nav-bar-item a {
	color: #e5ccab;
text-decoration: none;
}
.nav-bar-item {
	width: calc(100% / 4);
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.nav-bar-item i {
  color: #e5ccab;
	font-size: 30px;
}
.user-container {
	display: flex;
align-items: center;
flex-direction: column;
text-align: center;
padding-top: 40px;
width: 100%;
}
.user-container input {
	border: none;
	height: 50px;
	margin: 20px 0;
	border-radius: 50px;
	width: 70%;
	padding: 20px;
	text-align: center;
	font-size: 20px;

}
.acting-status-container {
	display: flex;
	justify-content: space-around;
}
.acting-status-container div i {
	font-size: 40px;
}
.acting-status-container label {
	cursor: pointer;
	color:rgb(255, 255, 255);
	opacity: 0.5;
}
.acting-status-container input {
	display: none;
}
.acting-status-container input:checked + label {
	opacity: 1;
}
.dop-info-container {
	display: flex;
	flex-direction: column;
}
.dop-info-container input {
	width: 270px;
	max-width: 90%;
	margin: 10px 0;
	padding: 5px 15px;
	border: 0;
	border-radius: 8px;
}
/* Modal */

.modal-window {
	position:fixed;
	background-color:rgb(47, 52, 59);
	top: 0;
	left: 50%;
	right: 0;
	bottom: 0;
	width: 100vw;
	max-width: 500px;
	max-height: 100%;
	overflow-x: auto;
	z-index: 1000;
	padding: 60px 45px 25px 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateX(-50%);
}
.close-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 30px;
  color: #e5ccab;
	cursor: pointer;
}
